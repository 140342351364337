#home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#welcomeContainer {
    width: 100%;
    min-height: 320px;
    background-color: var(--color-primary);
    color: var(--color-white);
    text-align: center;
    padding: 40px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#welcome {
    display: block;
    font-size: 28px;
    font-weight: 600;
}
#department {
    display: block;
    font-size: 35px;
    font-weight: 800;
    margin: 15px 0;
}
#college {
    display: block;
    font-size: 30px;
    font-weight: 700;
    margin: 10px 0;
}
#aboutContainer {
    width: 100%;
    min-height: 500px;
    padding: 35px 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2.5%;
    flex-direction: row;
    background-color: var(--color-white);
}
#leftAboutPart {
    width: 60%;
}
#rightAboutPart {
    width: 20%;
}
#wrapper {
    /* position: absolute;
    top: -170px; */
    width: 90%;
    height: 410px;
    margin: 25px 0;
}
#sliderImages {
    width: 100%;
    height: 410px;
    object-fit: cover;
}
.carousel .slider-wrapper {
    width: 50%;
}
#aboutDeptContent {
    width: 90%;
    position: relative;
    /* margin-top: 250px; */
}
#para {
    display: inline-block;
    font-size: 18px;
    margin: 10px 0;
    text-align: justify;
}
#quick-links-section {
    width: 100%;
    margin: 25px 0;
}
#quick-links-heading {
    background-color: var(--color-primary);
    color: var(--color-white);
    font-size: 23px;
    padding: 10px 20px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 25px;
}
#quick-links-heading::after {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--color-primary);
    bottom: -9px;
    display: block;
    left: 5%;
    margin: 0 auto;
    position: absolute;
    z-index: 9999;
}
#quick-links-list {
    list-style-position: inside;
    list-style-type: none;
    padding-left: 15px;
}
#quick-links-item {
    margin: 5px;
    display: flex;
    align-items: center;
}
#quick-links-item::before {
    color: transparent;
    font-size: 1px;
    content: " ";
    margin-left: -1.3em;
    margin-right: 15px;
    padding: 10px;
    background-color: var(--color-primary);
    -webkit-mask-image: url("../assets/quick-links-list-bullet.svg");
    -webkit-mask-size: calc(100%);
}
#quick-links-link {
    color: var(--color-primary);
    text-decoration: none;
    font-size: 1.15rem;
}
#quick-links-item:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: var(--color-primary);
    font-weight: 700;
}
#hodMessageContainer {
    width: 100%;
    min-height: 500px;
    background-color: var(--color-primary);
    padding: 50px 35px;
}
#hod-message {
    max-width: 1160px;
    width: 85%;
    margin: auto;
    flex: 0.70;
    padding: 15px;
}
#hod-message-head {
    display: grid;
    grid-template-columns: 30% 1fr;
    width: 100%;
    grid-template-rows: 270px;
    column-gap: 2rem;
}
#hod-message-head-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--color-ghost-white);
}
#hod-message-title {
    font-size: 2rem;
    color: var(--color-ghost-white);
}
#hod-message-head-img {
    width: 225px;
    height: 225px;
}
#hod-message-head-img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
#hod-message-subtitle {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: var(--color-ghost-white);
}
i {
    margin-right: 1rem;
    color: var(--color-ghost-white);
}
i.right {
    margin-left: 1rem;
    margin-right: 0;
}
#hod-message-desc {
    text-align: justify;
}
#hod-message-desc > span {
    display: inline-block;
    padding: 7px 0;
    font-size: 18px;
    color: var(--color-ghost-white);
}
.programContent {
    max-width: 1140px;
    width: 85%;
    min-height: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 20px;
    margin: auto;
    padding: 20px 15px;
    box-sizing: border-box;
}
.programOffered {
    font-size: 30px;
    margin: 0;
    padding: 30px 0 35px;
    font-weight: 800;
    text-align: center;
    color: var(--color-dark-oil);
}
.cardContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.card {
    width: 30%;
    margin: 25px 2.5%;
    height: 230px;
    background-color: var(--color-dark-oil);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
}
.programNumber {
    color: var(--color-ghost-white);
    font-size: 28px;
    text-align: right;
    font-weight: 700;
    margin: 15px;
    position: absolute;
    top: 0;
    right: 0;
}
.programTitle {
    display: inline-block;
    width: 90%;
    text-align: center;
    color: var(--color-white);
    font-size: 30px;
    font-weight: 900;
    line-height: 44px;
}
.knowMore {
    display: inline-block;
    width: 75%;
    text-align: center;
    background-color: var(--color-secondary);
    color: var(--color-dark-oil);
    font-size: 18px;
    padding: 8px;
    text-decoration: none;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    margin: 15px 15px 20px;
    font-weight: 600;
}

@media only screen and (max-width: 1024px) {
    #welcome {
        font-size: 25px;
    }
    #department {
        font-size: 32px;
    }
    #college {
        font-size: 28px;
    }
    #aboutContainer {
        gap: 0;
    }
    #leftAboutPart {
        width: 55%;
    }
    #rightAboutPart {
        width: 25%;
    }
    #wrapper {
        height: 320px;
    }
    #sliderImages {
        height: 320px;
    }
    #para {
        font-size: 16px;
    }
    #quick-links-item {
        margin: 10px 5px;
        font-size: 1rem;
    }
    #hod-message-head {
        grid-template-columns: 40% 1fr;
    }
    #hod-message-desc > span {
        font-size: 16px;
    }
    
}

@media only screen and (max-width: 768px) {
    #welcomeContainer {
        padding: 50px 30px;
    }
    #welcome {
        font-size: 22px;
        font-weight: 500;
    }
    #department {
        font-size: 35px;
    }
    #college {
        font-size: 25px;
    }
    #aboutContainer {
        flex-direction: column;
    }
    #leftAboutPart {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    #wrapper {
        width: 600px;
        height: 350px;
    }
    #sliderImages {
        height: 350px;
    }
    #aboutDeptContent {
        width: 80%;
    }
    #rightAboutPart {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    #quick-links-section {
        width: 80%;
        margin: 25px 0;
    }
    #hodMessageContainer {
        padding: 0;
    }
    #hod-message-head {
        grid-template-rows: 220px;
    }
    #hod-message-head-img {
        width: 200px;
        height: 200px;
    }
    #hod-message-title {
        font-size: 29px;
    }
    #hod-message-subtitle {
        font-size: 22px;
        margin: 15px 0;
    }
    #hod-message {
        width: 85%;
        margin: 7.5%;
        padding: 0;
    }
    .programContent {
        width: 100%;
        min-height: 400px;
        margin: 0;
    }
    .programOffered {
        font-size: 30px;
        font-weight: 900;
        padding: 0 15px 30px 15px;
    }
    .cardContainer {
        /* flex-direction: column; */
    }
    .card {
        width: 30%;
        height: 180px;
        margin: 10px;
    }
    .programNumber {
        font-size: 20px;
    }
    .programTitle {
        font-size: 24px;
    }
}

@media only screen and (max-width: 600px) {
    #aboutContainer {
        padding: 35px 0;
    }
    #welcomeContainer {
        padding: 50px 30px;
    }
    #welcome {
        font-size: 21px;
        font-weight: 600;
    }
    #department {
        font-size: 26px;
        font-weight: 900;
    }
    #college {
        font-size: 18px;
        font-weight: 500;
    }
    #aboutContainer {
        flex-direction: column;
    }
    #leftAboutPart {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    #wrapper {
        width: 320px;
        height: 190px;
    }
    #sliderImages {
        height: 190px;
    }
    #aboutDeptContent {
        width: 80%;
        position: relative;
    }
    #rightAboutPart {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    #quick-links-section {
        width: 80%;
        margin: 25px 0;
    }
    #para {
        font-size: 15px;
    }
    #hodMessageContainer {
        padding: 15px;
    }
    #hod-message {
        width: 90%;
        margin: 5%;
        padding: 0;
    }
    #hod-message-head {
        grid-template-columns: none;
        grid-template-rows: 250px;
    }
    #hod-message-title {
        font-size: 25px;
        padding-bottom: 10px;
    }
    #hod-message-subtitle {
        font-size: 17px;
        margin: 0 0 10px;
    }
    #hod-message-desc > span {
        font-size: 15px;
    }
    .programContent {
        width: 90%;
        margin: 0;
    }
    .programOffered {
        font-size: 26px;
        font-weight: 900;
        padding: 20px;
    }
    .cardContainer {
        flex-direction: column;
    }
    .card {
        width: 90%;
        height: 180px;
        margin: 15px;
    }
    .programNumber {
        font-size: 26px;
    }
    .programTitle {
        font-size: 24px;
    }
}

.popup-video-player{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width:90%;
    padding:3vw;
    background-color: rgb(181, 192, 255);
    border-radius: 10px;

    h1{
        color:#001C45;
        font-weight: bold;
    }
}