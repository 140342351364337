.topBanner {
    width: 100%;
    height: 200px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bannerTitle {
    font-size: 26px;
    color: var(--color-white);
    font-weight: 700;
    margin-left: 15%;
}
.container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.cardContainer {
    max-width: 1140px;
    width: 100%; /* lailt*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}
.facultyHeading {
    font-size: 28px;
    font-weight: 500;
    margin: 25px;
    color: #FFF;
    background-color: var(--color-primary);
    padding: 10px;
    width: 400px;
    text-align: center;
    text-shadow: 0px 2px 5px #1b1b1b;
    border-radius: 5px;
}
.rrdLink {
    text-decoration: none;
}
.card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 25px 25px;
    width: 500px;
    flex-basis: 50%;
    color: #1b1b1b;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 10px;
    border-radius: 5px;
}
.cardImg {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 5px;
}
.cardData {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    margin: auto 25px;
    width: 100%;
}
.cardName {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-primary);
}
.designation {
    font-size: 18px;
    font-weight: 500;
}
.qualification {
    font-size: 16px;
    font-weight: 400;
}
.facultyHeadingMCA {
    font-size: 28px;
    font-weight: 500;
    margin: 25px;
    color: #FFF;
    background-color: var(--color-primary);
    padding: 10px;
    width: 400px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
}

/* lalit */
@media screen and (max-width: 1117px) {
    .card {
        margin: 15px;
        width: 420px;
        flex-basis: 50%;
        padding: 10px;
    }
}
/* lalit ends */
@media screen and (max-width: 1024px) {
    .cardContainer {
        width: 100%;
    }
    .card {
        margin: 15px;
        width: 420px;
        flex-basis: 50%;
        padding: 10px;
    }
}
/* For small devices */
@media screen and (max-width: 768px) {
    .facultyHeading, .facultyHeadingMCA {
        font-size: 20px;
        width: 400px;
    }
    .cardContainer {
        width: 90%;
        flex-direction: column;
    }
    .card {
        margin: 20px 0;
        width: 450px;
    }
    .cardName {
        font-size: 18px;
    }
    .designation {
        font-size: 16px;
        font-weight: 500;
    }
    .qualification {
        font-size: 14px;
        font-weight: 400;
    }
}
/* For small devices */
@media screen and (max-width: 600px) {
    .topBanner {
        height: 200px;
        justify-content: center;
        text-align: center;
    }
    .bannerTitle {
        font-size: 22px;
        padding: 0 15px;
        margin-left: 0;
    }
    .cardContainer {
        width: 90%;
    }
    .facultyHeading, .facultyHeadingMCA {
        width: 85%;
    }
}