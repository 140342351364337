.topBanner {
    width: 100%;
    height: 200px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bannerTitle {
    font-size: 26px;
    color: var(--color-white);
    font-weight: 700;
    margin-left: 15%;
}
.outerContainer {
    max-width: 1140px;
    width: 80%;
    margin: auto;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.noticeTable {
    width: 100%;
    margin: 25px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 10px 2px, rgba(0, 0, 0, 0.24) 0px 1px 3px;
    border-radius: 5px;
    border-spacing: 10px;
}
.noticeTableHead {
    text-align: left;
    font-weight: 700;
    font-size: 20px;
}
.noticeTableHead th {
    padding-bottom: 15px;
}
.noticeTableBody td {
    padding: 2.5px 0;
}
.noticeLink {
    color: var(--color-accent);
}

@media screen and (max-width: 600px) {
    .topBanner {
        height: 200px;
        justify-content: center;
        text-align: center;
    }
    .bannerTitle {
        font-size: 22px;
        padding: 0 15px;
        margin-left: 0;
    }
    .outerContainer {
        width: 90%;
    }
    .noticeTableHead {
        font-size: 17px;
        vertical-align: text-top;
    }
    .noticeTableBody {
        font-size: 16px;
        vertical-align: text-top;
    }
}