:root{
    --bg-dark: rgb(93, 88, 86);
    --bg-medium-dark: rgb(148, 141, 141);
    --bg-medium: rgb(217, 217, 217);
    --bg-medium-light: rgb(238, 238, 238);
    --bg-light: rgb(245, 245, 245);
    --main-padding: 4rem;

    --color-dark: #181D31;
    --color-accent: #0F52BA;
    --color-white: #FFF;
    --color-white-glass: --color-white: rgba(255, 255, 255, 0.5);

    --color-primary: #001C45;
    --color-secondary: #7FB5FF;
    --color-white: #FFFFFF;
    --color-ghost-white: #FAFAFA;
    --color-dark-oil: #232323;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif;
}

.outer-wrapper{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container{
    width: 85%;
    margin: 50px 0px;
    row-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}
