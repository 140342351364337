/*maaz*/
.card {
    display: flex;
    /*background-color: aqua;*/
    width: auto;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  
  .imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  
  .img {
    width: 300px; 
    height: 300px; 
    border-radius: 50%;
    object-fit: cover; 
    overflow: hidden;
  }
  
  .info {
    padding: 10px;
    display: flex;
    flex-direction: column;
    /*background-color: orange;*/
    width: 65%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .name {
    width: 100%;
    height:auto;
    /*background-color: blue;*/
  }
  
  .details {
    width: 100%;
    height:auto;
    /*background-color: red;*/
  }
  
  .resumeButton {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height:auto;
    /*background-color: yellow;*/
  }
  
  @media screen and (max-width: 768px) {
    .card {
      height: auto;
      width: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
    }

    .imgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
      }
      
      .img {
        width: 300px;
        height: 300px; 
        border-radius: 50%;
        object-fit: cover; 
        overflow: hidden;
      }
  
    .name,
    .details,
    .resumeButton {
      height: auto; 
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  /*maaz*/
  
  .topBanner {
    width: 100%;
    height: 200px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .bannerTitle {
    font-size: 26px;
    color: var(--color-white);
    font-weight: 700;
    margin-left: 15%;
  }
  .facultyinfo {
    max-width: 1140px;
    width: 90%;
    margin: auto;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .goBack {
    color: var(--color-dark-oil);
    text-decoration: none;
    margin: 0 0 25px -10px;
  }
  
  .goBack:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    font-weight: 600;
  }
  
  .goBackText {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  
  .backIcon {
    margin: 0 8px 0 0;
  }
  
  /*
  #img{
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  #img img{
    width: 280px;
    height: 280px;
    object-position: center;
    object-fit: contain;
    margin: 15px 0;
  }
  */
  .title {
    font-size: 2.4rem;
    color: var(--color-primary);
    font-weight: 600;
  }
  
  .subtitle {
    font-size: 1.1rem;
  }
  
  /*
  .designation {
    font-size: 1.3rem;
  }
  */
  
  .heading {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-primary);
  }
  .resumeBtn {
    background-color: var(--color-primary);
    color: var(--color-white);
    text-decoration: none;
    font-size: 16px;
    margin: 15px 0 0;
    padding: 7.5px 20px;
    border-radius: 3px;
  }
  .dataContainer {
    margin: 20px 0;
  }
  .list {
    list-style-position: outside;
    padding: 15px 25px;
  }
  .list li {
    font-size: 1.1rem;
  }
  .contactInfo {
    padding: 15px 0;
    font-size: 1.1rem;
  }
  /* for smaller devices */
  
  @media screen and (max-width: 992px) {
    
    #facultyinfo{
        width: 95%;
        padding: 2rem 1rem;
    }
    
    .title {
      font-size: 1.8rem;
    }
    .subtitle {
      font-size: 1.1rem;
    }
    .designation {
      font-size: 1.3rem;
    }
    .education {
      font-size: 1.1rem;
    }
    .mainData {
      width: 100%;
    }
    .list {
      list-style-position: outside;
      padding: 15px 15px;
    }
    .list li {
      font-size: 0.9rem;
      overflow-wrap: break-word;
    }
  }
  @media screen and (max-width: 600px) {
    .topBanner {
      height: 200px;
      justify-content: center;
      text-align: center;
    }
  
    .bannerTitle {
      font-size: 22px;
      padding: 0 15px;
      margin-left: 0;
    }
  }
  