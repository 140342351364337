.topBanner {
    width: 100%;
    height: 200px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bannerTitle {
    font-size: 30px;
    color: var(--color-white);
    font-weight: 700;
    width: 100%;
    text-align: center;
}
.container {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
}
.icon {
    width: 150px;
    height: 150px;
    margin-bottom: 45px;
}
.title {
    font-size: 55px;
}
.message {
    font-size: 25px;
    text-align: center;
}
.button {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 10px 70px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    margin-top: 50px;
}

/* For small devices */
@media screen and (max-width: 992px) {
    .container {
        min-height: 550px;
        padding: 10px 30px;
    }
    .title {
        font-size: 40px;
    }
    .message {
        font-size: 20px;
        padding: 10px 0;
    }
    .button {
        padding: 10px 40px;
    }
}