#aboutus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
}
.heading {
    font-size: 35px;
    color: var(--color-dark);
    text-align: center;
}
#wrapper{
    width: 780px;
    height: 400px;
    margin: 25px 0;
    border-radius: 10px;
}
.sliderImages {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}
.carousel .slider-wrapper{
    width: 50%;
}
.aboutDept {
    max-width: 1160px;
    width: 80%;
}
.para {
    display: inline-block;
    font-size: 1.1rem;
    margin: 10px 0;
    text-align: justify;
}
@media screen and (max-width: 992px) {
    #aboutus {
        padding: 30px 10px;
    }
    .heading {
        font-size: 28px;
    }
    #wrapper{
        width: 320px;
        height: 220px;
    }
    .sliderImages {
        width: 100%;
        height: 220px;
        object-fit: cover;
    }
    .aboutDept {
        width: 90%;
    }
    .para {
        font-size: 0.9rem;
    }
}