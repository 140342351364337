.topBanner {
    width: 100%;
    height: 200px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bannerTitle {
    font-size: 26px;
    color: var(--color-white);
    font-weight: 700;
    margin-left: 15%;
}
.outerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
}
.wrapper {
    max-width: 1100px;
    width: 90%;
}
.title {
    font-size: 35px;
    padding-bottom: 30px;
    color: var(--color-dark-oil);
}
.comingSoonImage {
    width: 480px;
    height: 300px;
    pointer-events: none;
}
.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.card {
    width: 30%;
    margin: 25px 1%;
    min-height: 300px;
    background-color: #FFF;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.banner {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}
.data {
    padding: 10px 20px;
}
.eventTitle {
    text-align: left;
    color: #1b1b1b;
    font-size: 18px;
    display: inline-block;
}
.speaker {
    margin: 10px 0;
}
.speakerHeading {
    font-size: 17px;
    font-weight: 700;
}
.speakerRow {
    margin: 5px 0;
    list-style-position: inside;
}
.speakerName {
    font-weight: 600;
    font-size: 16px;
}
.speakerDesignation {
    font-size: 16px;
}
.dateHeading, .timeHeading {
    font-weight: 700;
    font-size: 17px;
}
.dateValue, .timeValue {
    font-size: 17px;
}
.document {
    background-color: var(--color-dark-oil);
    color: var(--color-white);
    padding: 7px 15px;
    margin: 10px 0;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
}

/* For small devices */
@media screen and (max-width: 1024px) {
    .card {
        width: 45%;
    }
    .title {
        font-size: 28px;
    }
    .comingSoonImage {
        width: 300px;
        height: 200px;
    }
}

@media screen and (max-width: 600px) {
    .topBanner {
        height: 200px;
        justify-content: center;
        text-align: center;
    }

    .bannerTitle {
        font-size: 22px;
        padding: 0 15px;
        margin-left: 0;
    }
    .card {
        width: 100%;
    }
    .title {
        font-size: 28px;
    }
    .comingSoonImage {
        width: 300px;
        height: 200px;
    }
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }

  .card {
    max-width: 300px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .card img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .card-content {
    padding: 20px;
    text-align: center;
  }
  
  .card-content h3 {
    margin-bottom: 10px;
  }
  
  .card-content p {
    margin-bottom: 20px;
  }
  
  .card-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .card-btn:hover {
    background-color: #2980b9;
  }