.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .popup-content {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 80%;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 20px rgb(0, 0, 0);
  }

.play-video-button {
    background-color: #001C45;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px; 
    font-weight: bold;
    cursor: pointer; 
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .play-video-button:hover {
    background-color: #B5C0FF; /* Darker blue background on hover */
    color :#001C45;
    font-weight: bolder;
  }
  
  .play-video-button:active {
    background-color: #B5C0FF; /* Even darker blue background when button is clicked */
    transform: scale(0.98); /* Slightly shrink the button on click */
  }
  